* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

.section5 {
    display: grid;
    grid-auto-flow: column;
    padding: 112px 50px;
    font-family: 'brother-1816';
    gap: 20px;
    background-color: #F1F4FA;
}

.text-9 {
    color: #11182A;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;

}

.text-10 {
    color: #3E5571;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Media Queries */
@media (max-width: 1980px) {

    .section5 .text-9,
    .text-10 {
        text-align: left;
        margin-left: 40px;



    }
}

@media (max-width: 1360px) {
    .section5 .text-9{
        margin-top: -30px;
        font-size: 22px;
    }

    .section5 .text-10 {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: -30px;
    }
}

@media (max-width: 1024px) {
    .section5 .text-9, .text-10{
        margin-left: 0px;
    }
    .section5 .text-9{
        font-size: 20px;
    }
    .section5 .text-10 {
        font-size: 15px;
        line-height: 15px;
    }
}

@media (max-width: 785px) {
    .section5 {
        padding: 80px 20px;
    

    }
    .section5 .text-9{
        font-size: 16px;
    }
    .section5 .text-10 {
        font-size: 11px;
        line-height: 12px;
    }
}