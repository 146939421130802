/* Estilos gerais */
* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'brother-1816';
  src: url(../fonts/brother-1816.ttf);
}

@font-face {
  font-family: 'brother-regular';
  src: url(../fonts/brother-regular.ttf);
}

@font-face {
  font-family: 'brother-medium';
  src: url(../fonts/brother-medium.ttf);
}

/* Estilos para Section1 */
.section1 {
  display: flex;
  width: 100%;
  background-image: url(../imagens/background.svg);
  background-repeat: no-repeat;
  padding-bottom: 80px;

  text-align: left;
}

img {
  width: 100%;
}

.sub-div {
  display: flex;
  flex-direction: column;
}

.section1 h1 {
  padding-top: 153px;
  margin-left: 120px;
  color: #fff;
  font-family: 'brother-regular';
  font-weight: 700;
  font-size: 40px;
}

.section1 .text-2 {
  padding-top: 14px;
  margin-left: 120px;
  color: #fff;
  font-family: 'brother-1816';
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
}

/* Media Queries */
@media (max-width: 1980px) {

  .section1 h1,
  .section1 .text-2 {
    margin-left: 80px;

  }

  .section1 {
    padding-bottom: 200px;
    background-size: cover;
  }
}

@media (max-width: 1360px) {
  .section1 {
    background-size: cover;
    width: auto;
    padding-bottom: 100px;
  }

  .section1 h1,
  .section1 .text-2 {
    margin-left: 60px;
  }
}

@media (max-width: 1024px) {
  .section1 {
    background-size: cover;
    width: auto;
    padding-bottom: 100px;

  }

  .section1 h1,
  .section1 .text-2 {
    margin-left: 50px;
    text-align: left;
  }

  .section1 h1 {
    padding-top: 100px;
    font-size: 30px;
  }

  .section1 .text-2 {
    font-size: 18px;
  }
}

@media (max-width: 785px) {
  .section1 {
    background-size: cover;
    width: 100%;
    padding-bottom: 40px;
    padding-top: 10px;
  }
}