* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

.section6 {
    display: flex;
    flex-direction: row;
    font-family: 'brother-1816';

}

.left {
    display: flex;
    height: 588px;
    padding: 0px 91px 0px 120px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.right {
    background-image: url(../imagens/div6.svg);
    width: 50%;
}

.text-11 {
    color: #11182A;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}

.text-12 {
    color: #3E5571;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Media Queries */
@media (max-width: 1980px) {
    .right {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .section6 .text-11,
    .text-12 {
        padding-left: 30px;

    }
    .text-12{
        font-size: 22px;
    }
}

@media (max-width: 1360px) {

    .section6 .text-11, .text-12 {
        margin-left: 20px;
    }
    .section6 .text-11 {
        margin-top: -50px;
        font-size: 26px; 
        line-height: 30px;
    }
    .section6 .text-12{
        line-height: 20 px;
        padding-top: 8px;
        padding-bottom: px;
        font-size: 16x;
    }
}

@media (max-width: 1024px) {
    .section6 .text-11, .text-12{
        margin-left: -70px;
    }
    .section6 .text-11 {
        font-size: 24px;
        line-height:22px;

    }
    .section6 .text-12{
        font-size: 16px;
        line-height: 17px;

    }
}

@media (max-width: 785px) {
    .section6 .left{
        width: 50%;
        margin: -100px;
        margin-left: -10px;
    
    }
    .section6 .text-11 {
        font-size: 16px;
        line-height:18px;

    }
    .section6 .text-12{
        font-size: 12px;
        line-height: 13px;

    }
}