@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
  }
  
  @font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
  }
  
  @font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
  }
  
.react-tel-input .form-control {
    border: 1px solid #3E5571;
    padding: 10px;
    border-radius: 4px;
    width: 460px;
    border-radius: 20px;
    height: 40px;
    padding-left: 50px;
}
.react-tel-input {
    font-family: 'brother-regular';
    font-size: 15px;
    position: relative;
    /* width: 100%; */
    width: 460px;
    border-radius: 30px;
    border: 0;
    
}
.react-tel-input .flag-dropdown {
  
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.phone-input-valid {border: 1px solid green;}
.phone-input-invalid {border: 1px solid red;}