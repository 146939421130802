* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

.section4 {
    display: flex;
    flex-direction: row;
    font-family: 'brother-regular';
    
    

}

.bottom-left {
    width: 50%;
    background-image: url(../imagens/div3.svg);
    background-size: cover;
}
.bottom-right{
    width: 50%;
}

.text-7 {
    color: #11182A;
    font-family: 'brother-1816';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin: 200px 90px 0px 80px;

 
}

.text-8 {
    color: #3E5571;
    font-family: 'brother-1816';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 8px;
    margin-left: 80px;
    padding-bottom: 200px;

}
/* Media Queries */
@media (max-width: 1980px) {
    .bottom-right .text-7, .text-8{
        padding-left: 60px;
    }
    .text-8{
        font-size: 22px;

    }

  }
  
  @media (max-width: 1360px) {
    .bottom-right .text-7, .text-8 {
        margin-left: 20px;
    }
    .bottom-right .text-7 {
        margin-top: 120px; 
    }
    .bottom-right .text-8{
        line-height: 28px;
        padding-top: 8px;
        font-size: 15px;
        padding-right: 80px;
    }
   
  }
  
  @media (max-width: 1024px) {
    .section4 .text-7 {
        font-size: 25px;
        line-height: 20px;
    }
    .section4 .text-8{
        line-height: 17px;
        padding-bottom: 80px;
    }
    
  }
  
  @media (max-width: 785px) {
    .section4 .text-7 {
        font-size: 18px;
        line-height: 15px;
        padding-top: 90px;
        margin-top: 0px;


    }
    .section4 .text-8{
        font-size: 12px;
        line-height: 12px;
        padding-right: 30px;
        padding-bottom: 50px;
    }
    
   
  }