* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

.form {
    display: flex;
    flex-direction: column;
    background-color: #F1F4FA;
    justify-content: center;
    align-items: center;
    width: 100%;
}

select {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 1px solid #3E5571;
    font-size: 20px;
    font-family: 'brother-regular';
    color: #11182A;
    text-align: center;
    padding: 3px;
    width: 455px;
    border-radius: 20px;

}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 432px;
    padding: 24px;

}

input {
    padding: 12px 12px 12px 16px;
    border-radius: 20px;
    border: 1px solid #3E5571;
    width: 100%;
    background-color: #FFF;

}

.text-13 {
    color: #11182A;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin-top: 80px;
    /* padding-bottom: 0px; */
}

.text-14 {
    padding-bottom: 5px;
    padding-top: 40px;
    color: #3E5571;
}

.msg {
    display: flex;
    height: 140px;
    width: 435px;
    border: 1px solid #3E5571;
    border-radius: 10px;
    padding: 10px;
    font-family: 'brother-1816';
    font-size: 18px;
    color: #3E5571;


}

.btn {

    margin-top: 50px;
    padding: 12px 12px 12px 16px;
    border-radius: 20px;
    border: none;
    width: 452px;
    background-color: #6EB52C;
    color: #FFF;
    font-family: 'brother-1816';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 100px;
    /* line-height: 34px; */

}

.btn:hover {
    background-color: #92D322;
}

/* validação */

.valid {
    border-color: green;
    /* Outros estilos verdes, se necessário */
  }
  
  .invalid {
    border-color: red;
    /* Outros estilos vermelhos, se necessário */
  }