* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

.section3 {
    display: flex;
    flex-direction: row;
    font-family: 'brother-regular';

}

.top-right {
    width: 50%;
    background-image: url(../imagens/div2.svg);
    background-image: cover;

}

.top-left {
    width: 50%;
}

.text-5 {
    color: #11182A;
    font-family: 'brother-1816';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin-left: 80px;
    padding-top: 180px;

}

.text-6 {
    color: #3E5571;
    font-family: 'brother-1816';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px 90px 0px 80px;
    padding-bottom: 180px;

}

/* Media Queries */
@media (max-width: 1980px) {
    .top-right {
        background-size: cover;
        background-repeat: no-repeat;

    }

    .section3 .text-5,
    .text-6 {
        padding-left: 50px;

    }

    .text-6 {
        font-size: 22px;

    }
}

@media (max-width: 1360px) {
    .section3 .text-5, .text-6 {
        margin-left: 20px;
    }
    .section3 .text-5 {
        margin-top: -50px; 
    }
    .section3 .text-6{
        line-height: 30px;
        padding-top: 8px;
        padding-bottom: 100px;
        font-size: 15px;
    }
}

@media (max-width: 1024px) {
   
    .section3 .text-5 {
        font-size: 25px;
        line-height: 20px;
    }
    .section3 .text-6{
        line-height: 17px;

    }
}

@media (max-width: 785px) {
    .section3 .text-5 {
        font-size: 18px;
        line-height: 15px;
        padding-top: 90px;

    }
    .section3 .text-6{
        font-size: 12px;
        line-height: 12px;
        padding-right: 30px;
        padding-bottom: 50px;

    }
}