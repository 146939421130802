@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
}

.nav-left img {
    margin-top: 5px;
    margin-left: 30px;
    width: 100%;
    height: 50px;
}

.nav-right {
    display: flex;
    margin-right: 42px;

}

.menu {
    list-style: none;
    display: flex;
    align-items: center;

}

.menu-item a {
    margin-left: 20px;
    cursor: pointer;
    color: #3E5571;
    text-decoration: none;
    font-family: 'brother-regular';
    font-size: 20px;
}

a:hover {
    color: #6EB52C;
}