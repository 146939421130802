* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

.section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    background-color: #11182A;
    border: none;
    padding-top: 80px;
    padding-bottom: 80px;
    font-family: 'brother-1816';
}

.text-3 {
    font-family: 'brother-regular';
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
}

.text-4 {
    font-family: 'brother-regular';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;

}

/* Media Queries */
@media (max-width: 1980px) {

    
}

@media (max-width: 1360px) {

    
}

@media (max-width: 1024px) {
    .section2 .text-3{
        font-size: 36px;
        margin-top: -30px;
    }
    .section2 .text-4{
        font-size: 18px;
    }
}

@media (max-width: 785px) {
    .section2 .text-3{
        font-size: 26px;
        margin-top: -30px;
    }
    .section2 .text-4{
        font-size: 14px;
    }
    
}