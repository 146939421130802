* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'brother-1816';
    src: url(../fonts/brother-1816.ttf);
}

@font-face {
    font-family: 'brother-regular';
    src: url(../fonts/brother-regular.ttf);
}

@font-face {
    font-family: 'brother-medium';
    src: url(../fonts/brother-medium.ttf);
}

.ftr {
    display: flex;
    
    background-color: #11182A;
    padding: 10px;  
    
}

.ftr-x {

    color: #FFF;
    font-family: 'brother-1816';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: right;
    width: 100%;
    padding: 10px;

}